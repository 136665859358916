import React from "react"
import SEO from "../components/seo"
import {navigate, useIntl, Link} from "gatsby-plugin-intl";

const Legal = () => {
    const intl = useIntl();
    return (
        <>
          <SEO title="LEGAL NOTICE" />

            <h2>{intl.formatMessage({ id: "legals.preambule" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text1" })}} />

            <h2>{intl.formatMessage({ id: "legals.eligibilite" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text2" })}} />

            <h2>{intl.formatMessage({ id: "legals.programme" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text3" })}} />

            <h2>{intl.formatMessage({ id: "legals.evoluer.title" })}</h2>
            <h3>{intl.formatMessage({ id: "legals.evoluer.subtitle1" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.evoluer.text1" })}} />
            <h3>{intl.formatMessage({ id: "legals.evoluer.subtitle2" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.evoluer.text2" })}} />

            <h3>{intl.formatMessage({ id: "legals.evoluer.subtitle3" })}</h3>

              <div className="table-responsive-md mb-5">

                <table className="table">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "legals.evoluer.table.row1.cell1" })}</th>
                      <th>{intl.formatMessage({ id: "legals.evoluer.table.row1.cell2" })}</th>
                      <th>{intl.formatMessage({ id: "legals.evoluer.table.row1.cell3" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row2.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row2.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row2.cell3"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row3.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row3.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row3.cell3"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row4.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row4.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row4.cell3"})}</td>
                  </tr>
                  <tr>
                    <td rowSpan="2" className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row5.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row5.cell2.1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row5.cell3.1"})}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row5.cell2.2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row5.cell3.2"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row6.cell1"})}</td>
                    <td><div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.evoluer.table.row6.cell2" })}} /></td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row6.cell3"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row7.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row7.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row7.cell3"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row8.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row8.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row8.cell3"})}</td>
                  </tr>
                  <tr>
                    <td className="table-first-cell">{intl.formatMessage({id: "legals.evoluer.table.row9.cell1"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row9.cell2"})}</td>
                    <td>{intl.formatMessage({id: "legals.evoluer.table.row9.cell3"})}</td>
                  </tr>
                  </tbody>
                </table>

              </div>

            <p className="small">{intl.formatMessage({ id: "legals.evoluer.asterisque1" })}</p>
            <p className="small">{intl.formatMessage({ id: "legals.evoluer.asterisque2" })}</p>

            <h2>{intl.formatMessage({ id: "legals.profiter.title" })}</h2>
            <h3>{intl.formatMessage({ id: "legals.profiter.subtitle1" })}</h3>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.profiter.text1" })}} />

            <h3>{intl.formatMessage({ id: "legals.profiter.subtitle2" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.profiter.text2" })}} />


            <h3>{intl.formatMessage({ id: "legals.profiter.subtitle3" })}</h3>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.profiter.text3" })}} />

            <h2>{intl.formatMessage({ id: "legals.suivi" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text4" })}} />

            <h2>{intl.formatMessage({ id: "legals.taxes" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text5" })}} />

            <h2>{intl.formatMessage({ id: "legals.garantie" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text6" })}} />

            <h2>{intl.formatMessage({ id: "legals.responsable" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text7" })}} />

            <h2>{intl.formatMessage({ id: "legals.renonciation" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text8" })}} />

            <h2>{intl.formatMessage({ id: "legals.limitation" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text9" })}} />

            <h2>{intl.formatMessage({ id: "legals.choix" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text10" })}} />

            <h2>{intl.formatMessage({ id: "legals.integralite" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text11" })}} />
        </>
    )
}

export default Legal
