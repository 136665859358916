export const isBrowser = () => typeof window !== "undefined";
const pagesAccessibleWithoutSession = [
  'home',
  'login',
  'signup',
  'logout',
  'password-reset',
  'password-reset-confirm',
  'password-reset-link',
  'password-reset-link-expired',
  'password-reset-new',
  '404',
  'cookies',
  'legal',
  'terms',
  'privacy',
  'account-activated',
  'email-validation',
  'link-expired'
];

export const getUser = () => {
  if (isBrowser() && localStorage.getItem("currentUser")) {
    if (undefined !== localStorage.getItem("currentUser") && "undefined" !== localStorage.getItem("currentUser")) {
      return JSON.parse(localStorage.getItem("currentUser"));
    }
  }
  return {};
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.email
};

export const canIBeHere = (path) => {
  if (isLoggedIn()) {
    return true;
  } else {
    if (pagesAccessibleWithoutSession.indexOf(path) !== -1) {
      return true;
    }
  }

  return false;
};
