import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useIntl, Link, navigate, changeLocale} from "gatsby-plugin-intl"
import { Helmet } from 'react-helmet'
import {Modal} from "react-bootstrap";
import Footer from "./footer"
import Legal from "../pages/legal"
import jwt_decode from "jwt-decode"
import {canIBeHere, getUser} from "../services/auth";
import ENV from "../../env";
import firebase from '../notification/firebase';


const Layout = ({ children }) => {
  const intl = useIntl();
  const [modalShow, setModaleShow] = useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  let oneTrustId = "6245f573-4090-4f24-bda5-971ea7790e7b";

  useEffect(() => {
    let fullToken = localStorage.getItem('token');
    if (fullToken !== null) {
      let token = fullToken.replace('Bearer ','');
      let decoded = jwt_decode(token);
      let exp = decoded.exp;
      const now = new Date();
      const nowInSeconds = Math.round(now.getTime() / 1000);
      if (nowInSeconds >= exp) {
        navigate('/sessions/logout');
      }
    }
    //Appel à l'api de firebase pour obtenir le token du device
    if(firebase.messaging.isSupported())
    {
      const messaging = firebase.messaging();
    messaging.requestPermission().then(()=>{
      return messaging.getToken()
    }).then(token=>{
      console.log('Token : ', token)
    }).catch(()=>{
      console.log('error');
    })
    }

  }, []);

  let parts = url.split('/');
  let currentPage = parts[parts.length - 1];
  if (currentPage === "" || currentPage.startsWith('?')) {
    currentPage = parts[parts.length - 2];
  }


  let hostname;
  let country;

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  }
  else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  if ( ( (hostname === ENV.BE_YOUPLUS_URL) || (hostname === ENV.BE_YOUPLUS_URL_2) ) ) {
    country = "BE";
    oneTrustId = "6778a907-94c9-4185-945d-93924bc1ae4b";
  }
  else if ( ( (hostname === ENV.NL_YOUPLUS_URL) || (hostname === ENV.NL_YOUPLUS_URL_2) ) ) {
    country = "NL";
    oneTrustId = "6245f573-4090-4f24-bda5-971ea7790e7b";
  }
  else if ( ( (hostname === ENV.LU_YOUPLUS_URL) || (hostname === ENV.LU_YOUPLUS_URL_2) ) ) {
    country = "LU";
    oneTrustId = "7d9c732f-f711-4fbc-ba00-5bacd8de7fd0";
  }



  const handleClose = () => {
    setModaleShow(false);
  };

  const toggleModale = (e) => {
    e.preventDefault();
    setModaleShow(true)
  }

  const mainContent = () => {
    if(window.location.pathname.split('/')[2] === "home" ) {
      return (
        <>
          <main>{children}
          <footer className="d-flex justify-content-around d-md-block text-center footer">
            <Link to="#" className="mx-md-4" onClick={(e) => toggleModale(e)}>{intl.formatMessage({id: "footer.terms"})}</Link>
          </footer>
          </main>
        </>
      )

    } else {
      return (
        <div className="wrapper-nn">
          <div className="container">

            
          {/* MODAL ALREADY DL */}
          <div className="modal fade" id="modalAlready" tabindex="-1" role="dialog" aria-labelledby="modalAlreadyLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-white p-0">
                    <button type="button" className="close p-0 mr-3 mt-3" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body p-0">
                    <div className="icon-download mb-3"></div>
                    <p className="like-h1 mb-3">{intl.formatMessage({id: "modal.event_already_downloaded.title"})}</p>
                    <p className="mb-4">{intl.formatMessage({id: "modal.event_already_downloaded.body"})}</p>
                    <div className="text-center mb-5">
                      <Link className="button red" href="" data-dismiss="modal"><span>OK</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <main>{children}</main>
            <footer className="d-flex justify-content-around d-md-block text-center footer">
              <Link to="#" className="mx-md-4" onClick={(e) => toggleModale(e)}>{intl.formatMessage({id: "footer.terms"})}</Link>
            </footer>
          </div>
        </div>
      )
    }
  }

  if (false === canIBeHere(currentPage)) {
    navigate('/sessions/login');
    return null;
  } else {
    return (
      <>
        <Helmet>

          {((hostname === ENV.NL_YOUPLUS_URL) || (hostname === ENV.NL_YOUPLUS_URL_2)) && (
            <>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-5W5FFL14TE"></script>
              <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-5W5FFL14TE');
                `}
              </script>
            </>
          )}


          <link rel="stylesheet" media="print, screen"
                href={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/css/PACE-header-footer-v3.min.css"}/>
          <script
            src={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/js/PACE-header-footer-v3.min.js"}
            className="heliosinclude"></script>

          <script>
            {`
              window.googleDataLayer = window.googleDataLayer || [];
                 (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','googleDataLayer','GTM-TZL8TT3');
            `}
          </script>

        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true"
                type="text/javascript" charSet="UTF-8"
                data-domain-script={oneTrustId}></script>
        <script>
          {`
            function OptanonWrapper() {}
          `}
        </script>

        </Helmet>
       {mainContent()}

        {/*<div id="nissan_global_footer" className="helios"></div>*/}
        <Footer/>

        <Modal size="lg" centered show={modalShow} onHide={() => handleClose()} animation={false} id="legals">
          <Modal.Header className="justify-content-center">
            <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="modalAlreadyCenterTitle">{intl.formatMessage({ id: "footer.terms" })}</h2>
            <button type="button" className="close" onClick={() => handleClose()}>
                  <span aria-hidden="true">
                    <span className="icon-close"></span>
                  </span>
                </button>
          </Modal.Header>
          <Modal.Body className="text-center px-4 pt-4 pb-4 " >
            <Legal />
            <button class="button red mx-auto mr-md-0" onClick={() => handleClose()}>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</button>
          </Modal.Body>
        </Modal>

        <script
          src={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/js/PACE-header-footer-v3.min.js"}
          className="heliosinclude"></script>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
