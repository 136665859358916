import React, {useState, useEffect, useRef} from "react"
import {useIntl, Link, FormattedMessage, navigate} from "gatsby-plugin-intl"

const FooterFrBe = ({ mDropDown, mDropDownToggle, closeAll }) => {

  const intl = useIntl();

  return (
    <>
    <footer itemScope="" itemType="http://schema.org/WPFooter" className="grid-row bleed">
      <nav className="c_054-3">
        <div className="grid-row">
          <div className="col-12">
            <dl className="col-3">
              <dt className={ mDropDown.menu1 ? "is-open" : "" }>
                <a href="#" className="accordionToggle" onClick={(e) => mDropDownToggle(e, "menu1")}>
                  <span>Toggle {intl.formatMessage({ id: "nissan_footer.menu1.label" })} menu
                  </span>
                </a>
                <span>{intl.formatMessage({ id: "nissan_footer.menu1.label" })}</span>
              </dt>
              <dd className={ mDropDown.menu1 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu1.subitems.1.link" })} data-adobe-tagging="customer-service|faq">
                  <span>{intl.formatMessage({ id: "nissan_footer.menu1.subitems.1.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu1 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu1.subitems.2.link" })} data-adobe-tagging="customer-service|glossary">
                  <span>{intl.formatMessage({ id: "nissan_footer.menu1.subitems.2.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu1 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu1.subitems.3.link" })} data-adobe-tagging="customer-service|contact-us">
                  <span>{intl.formatMessage({ id: "nissan_footer.menu1.subitems.3.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu1 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu1.subitems.4.link" })} data-adobe-tagging="wltp">
                  <span>{intl.formatMessage({ id: "nissan_footer.menu1.subitems.4.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu1 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu1.subitems.5.link" })} data-adobe-tagging="gdpr">
                  <span>{intl.formatMessage({ id: "nissan_footer.menu1.subitems.5.label" })}</span>
                </a>
              </dd>
            </dl>
            <dl className="col-3">
              <dt className={ mDropDown.menu2 ? "is-open" : "" }>
                <a href="#" className="accordionToggle" onClick={(e) => mDropDownToggle(e, "menu2")}>
                  <span>Toggle {intl.formatMessage({ id: "nissan_footer.menu2.label" })} menu
                  </span>
                </a>
                <span>{intl.formatMessage({ id: "nissan_footer.menu2.label" })}</span>
              </dt>
              <dd className={ mDropDown.menu2 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu2.subitems.1.link" })}>
                  <span>{intl.formatMessage({ id: "nissan_footer.menu2.subitems.1.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu2 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu2.subitems.2.link" })}>
                  <span>{intl.formatMessage({ id: "nissan_footer.menu2.subitems.2.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu2 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu2.subitems.3.link" })}>
                  <span>{intl.formatMessage({ id: "nissan_footer.menu2.subitems.3.label" })}</span>
                </a>
              </dd>
            </dl>
            <dl className="col-3">
              <dt className={ mDropDown.menu3 ? "is-open" : "" }>
                <a href="#" className="accordionToggle" onClick={(e) => mDropDownToggle(e, "menu3")}>
                  <span>Toggle {intl.formatMessage({ id: "nissan_footer.menu3.label" })} menu
                  </span>
                </a>
                <span>{intl.formatMessage({ id: "nissan_footer.menu3.label" })}</span>
              </dt>
              <dd className={ mDropDown.menu3 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu3.subitems.1.link" })}>
                  <span>{intl.formatMessage({ id: "nissan_footer.menu3.subitems.1.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu3 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu3.subitems.2.link" })} >
                  <span>{intl.formatMessage({ id: "nissan_footer.menu3.subitems.2.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu3 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu3.subitems.3.link" })} >
                  <span>{intl.formatMessage({ id: "nissan_footer.menu3.subitems.3.label" })}</span>
                </a>
              </dd>
              <dd className={ mDropDown.menu3 ? "is-visible" : "" }>
                <a href={intl.formatMessage({ id: "nissan_footer.menu3.subitems.4.link" })} >
                  <span>{intl.formatMessage({ id: "nissan_footer.menu3.subitems.4.label" })}</span>
                </a>
              </dd>
            </dl>
            <dl className="col-3 last">
              <dt>
                <a href="#" className="accordionToggle">
                  <span>Toggle {intl.formatMessage({ id: "nissan_footer.menu4.label" })} menu</span>
                </a>
                <span>{intl.formatMessage({ id: "nissan_footer.menu4.label" })}</span>
              </dt>
              <dd>
                <a className="social-icon icon-facebook" href={intl.formatMessage({ id: "nissan_footer.menu4.subitems.1.link" })} rel="">
                  <span>facebook</span>
                </a>
              </dd>
              <dd>
                <a className="social-icon icon-youtube" href={intl.formatMessage({ id: "nissan_footer.menu4.subitems.2.link" })} rel="">
                  <span>twitter</span>
                </a>
              </dd>
              <dd>
                <a className="social-icon icon-twitter" href={intl.formatMessage({ id: "nissan_footer.menu4.subitems.3.link" })} rel="">
                  <span>youtube</span>
                </a>
              </dd>
            </dl>
          </div>
        </div>
      </nav>
    </footer>

    <footer className="grid-row bleed">
      <nav className="c_025 ">
        <div className="grid-row">
          <div className="col-12">
            <ul className="footer-options">
              <li>
                <a href={intl.formatMessage({ id: "nissan_footer.other_links.subitems.1.link" })} title="">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.1.label" })}</a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "nissan_footer.other_links.subitems.2.link" })} title="">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.2.label" })}</a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "nissan_footer.other_links.subitems.3.link" })} title="">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.3.label" })}</a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "nissan_footer.other_links.subitems.4.link" })} title="">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.4.label" })}</a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "nissan_footer.other_links.subitems.5.link" })} title="">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.5.label" })}</a>
              </li>
            </ul>
            <div className="footer-legal">
              <ul>
                <li>
                  <Link to="/privacy">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.6.label" })}</Link>
                </li>
                <li>
                  <Link to="/cookies">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.7.label" })}</Link>
                </li>
                <li>
                  <Link to="/terms">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.8.label" })}</Link>
                </li>
              </ul>
              <p className="footer-copyright">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.9.label" })}</p>
            </div>
          </div>
        </div>
      </nav>
    </footer>
    </>
  )
}

export default FooterFrBe