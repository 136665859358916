import React, {useState, useEffect, useRef} from "react";
import {useIntl, Link, FormattedMessage, navigate} from "gatsby-plugin-intl";

import FooterFrBe from "./header-footer/footer-fr-be";
import FooterFrLu from "./header-footer/footer-fr-lu";
import FooterNlBe from "./header-footer/footer-nl-be";
import FooterNlNl from "./header-footer/footer-nl-nl";

const Footer = () => {
  const [mDropDown, setMDropDown] = useState({
    menu1: false,
    menu2: false,
    menu3: false
  })

  const closeAll = () => {
    setMDropDown({
      menu1: false,
      menu2: false,
      menu3: false,
    })
  }

  const mDropDownToggle = (e, menu) => {
    e.preventDefault();
    closeAll();
    // Reset selected menu
    switch(menu) {
      case "menu1":
        setMDropDown({
          menu1: !mDropDown.menu1,
          menu2: false,
          menu3: false
        })
      break;
      case "menu2":
        setMDropDown({
          menu1: false,
          menu2: !mDropDown.menu2,
          menu3: false
        })
      break;
      case "menu3":
        setMDropDown({
          menu1: false,
          menu2: false,
          menu3: !mDropDown.menu3,
        })
      break;
    }
  }

   const footerRender = () => {
    if(typeof window !== 'undefined' && window.location.href.includes("fr-BE")) return (
      <FooterFrBe mDropDown={mDropDown} closeAll={closeAll} mDropDownToggle={mDropDownToggle} />
    );
    if(typeof window !== 'undefined' && window.location.href.includes("fr-LU")) return (
      <FooterFrLu mDropDown={mDropDown} closeAll={closeAll} mDropDownToggle={mDropDownToggle} />
    );
    if(typeof window !== 'undefined' && window.location.href.includes("nl-BE")) return (
      <FooterNlBe mDropDown={mDropDown} closeAll={closeAll} mDropDownToggle={mDropDownToggle} />
    );
    if(typeof window !== 'undefined' && window.location.href.includes("nl-NL")) return (
      <FooterNlNl mDropDown={mDropDown} closeAll={closeAll} mDropDownToggle={mDropDownToggle} />
    );
  }

  return (
    <div id="nissan_global_footer2" className="helios">
      {footerRender()}
    </div>
  )
}


export default Footer
