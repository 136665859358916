import firebase from "firebase";

const config = {
    apiKey: "AIzaSyCfe5_h_uI1XjgmZPSPtQVfjePH1bSSLJg",
    authDomain: "nissan-now-push-notif.firebaseapp.com",
    projectId: "nissan-now-push-notif",
    storageBucket: "nissan-now-push-notif.appspot.com",
    messagingSenderId: "37859220475",
    appId: "1:37859220475:web:2a44ad0611e4162da6495e",
    measurementId: "G-SJZ30WQ5HP"
};

firebase.initializeApp(config);

export default firebase;